*,
::after,
::before {
	box-sizing: border-box;
}

html {
	font-size: 100%;
	overflow-y: overlay;
}

#root {
	overflow-x: clip;
}

/* variables */
:root {
	/* theme */
	--theme: #e43800;
	--darkTheme: #b63307;
	--lightTheme: #ffcccc;

	/* hsl primary */
	--hue: 15;
	--sat: 100%;

	/* primary */
	--primary-100: hsl(var(--hue), var(--sat), 90%);
	--primary-200: hsl(var(--hue), var(--sat), 80%);
	--primary-300: hsl(var(--hue), var(--sat), 70%);
	--primary-400: hsl(var(--hue), var(--sat), 60%);
	--primary-500: hsl(var(--hue), var(--sat), 50%);
	--primary-600: hsl(var(--hue), var(--sat), 40%);
	--primary-700: hsl(var(--hue), var(--sat), 30%);
	--primary-800: hsl(var(--hue), var(--sat), 20%);
	--primary-900: hsl(var(--hue), var(--sat), 10%);

	/* secondary */
	--secondary-100: hsl(calc(var(--hue) + 180), var(--sat), 90%);
	--secondary-200: hsl(calc(var(--hue) + 180), var(--sat), 80%);
	--secondary-300: hsl(calc(var(--hue) + 180), var(--sat), 70%);
	--secondary-400: hsl(calc(var(--hue) + 180), var(--sat), 60%);
	--secondary-500: hsl(calc(var(--hue) + 180), var(--sat), 50%);
	--secondary-600: hsl(calc(var(--hue) + 180), var(--sat), 40%);
	--secondary-700: hsl(calc(var(--hue) + 180), var(--sat), 30%);
	--secondary-800: hsl(calc(var(--hue) + 180), var(--sat), 20%);
	--secondary-900: hsl(calc(var(--hue) + 180), var(--sat), 10%);

	/* grey */
	--grey-50: #f8fafc;
	--grey-100: #f1f5f9;
	--grey-200: #e2e8f0;
	--grey-300: #cbd5e1;
	--grey-400: #94a3b8;
	--grey-500: #64748b;
	--grey-600: #475569;
	--grey-700: #334155;
	--grey-800: #1e293b;
	--grey-900: #0f172a;

	/* colors */
	--black: #080808;
	--white: #f9f9f9;
	--darkerGray: #484848;
	--darkGray: #898989;
	--gray: #b7b7b7;
	--lightGray: #eaeaea;
	--orange: #fdbd47;
	--green: #229f22;

	/* groups */
	--s0: #f9f9f9;
	--s1: #f5d7d7;
	--s2: #e4a7a7;
	--s3: #e06666;
	--s4: #cc0000;
	--s5: #990000;
	--s6: #660000;
	--s7: #440000;
	--s8: #000000;

	/* results */
	--cd: #c7d9c6;
	--cc: #ffe599;
	--ci: #f8a7a7;

	/* fonts  */
	--headingFont: 'Roboto', sans-serif;
	--bodyFont: 'Montserrat', sans-serif;
	--smallText: 0.7em;

	/* vars */
	--backgroundColor: var(--white);
	--textColor: var(--grey-900);
	--borderRadius: 0.25rem;
	--letterSpacing: 1px;
	--transition: 0.3s ease-in-out all;
	--fixed-width: 600px;
	--overlay: rgba(0, 0, 0, 0.4);
	--page-width: 80vw;
	--mobile-width: 95vw;
	--navbar-height: 4rem;
	--footer-height: 9rem;

	/* box shadow*/
	--shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
	0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
	0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
	0 10px 10px -5px rgba(0, 0, 0, 0.04);
	--shadow-5: 0 0 8px 0 rgba(0, 0, 0, 0.05),
	0 2px 8px 0 rgba(0, 0, 0, 0.08);
	--shadow-6: inset 0 -3px 0 0 var(--primary-400);
}

body {
	background: var(--backgroundColor);
	font-family: var(--bodyFont);
	font-weight: 300;
	color: var(--textColor);
}

p {
	margin-top: 0;
	margin-bottom: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: var(--headingFont);
	font-weight: 500;
	line-height: 1.3;
	letter-spacing: var(--letterSpacing);
	color: var(--theme);
}

h1 {
	font-size: 3.052rem;
}

h2 {
	font-size: 2.441rem;
}

h3 {
	font-size: 1.953rem;
}

h4 {
	font-size: 1.563rem;
}

h5 {
	font-size: 1.25rem;
}

h6 {
	font-size: 0.85rem;
}

small,
.text-small {
	font-size: var(--smallText);
}

a {
	text-decoration: none;
}
ul {
	list-style-type: none;
	padding: 0;
}

.img {
	width: 100%;
	display: block;
	object-fit: cover;
}

/* buttons */
.btn {
	cursor: pointer;
	color: var(--white);
	background: var(--primary-600);
	border: transparent;
	border-radius: var(--borderRadius);
	letter-spacing: var(--letterSpacing);
	padding: 0.375rem 0.75rem;
	box-shadow: var(--shadow-1);
	transition: var(--transition);
	text-transform: capitalize;
	display: inline-block;
}
.btn:hover {
	background: var(--primary-700);
	box-shadow: var(--shadow-3);
}
.btn-hipster {
	color: var(--primary-500);
	background: var(--primary-200);
}
.btn-hipster:hover {
	color: var(--primary-200);
	background: var(--primary-700);
}
.btn-block {
	width: 100%;
}

/* alerts */
.alert {
	padding: 0.375rem 0.75rem;
	margin-bottom: 1rem;
	border-color: transparent;
	border-radius: var(--borderRadius);
}

.alert-danger {
	color: var(--red-dark);
	background: var(--red-light);
}
.alert-success {
	color: var(--green-dark);
	background: var(--green-light);
}

/* form */
.form {
	width: 90vw;
	max-width: var(--fixed-width);
	background: var(--white);
	border-radius: var(--borderRadius);
	box-shadow: var(--shadow-2);
	padding: 2rem 2.5rem;
	margin: 3rem auto;
}
.form-label {
	display: block;
	font-size: var(--smallText);
	margin-bottom: 0.5rem;
	text-transform: capitalize;
	letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea {
	width: 100%;
	padding: 0.375rem 0.75rem;
	border-radius: var(--borderRadius);
	background: var(--backgroundColor);
	border: 1px solid var(--grey-200);
}

.form-row {
	margin-bottom: 1rem;
}

.form-textarea {
	height: 7rem;
}
::placeholder {
	font-family: inherit;
	color: var(--grey-400);
}
.form-alert {
	color: var(--red-dark);
	letter-spacing: var(--letterSpacing);
	text-transform: capitalize;
}

input,
textarea {
	outline: none;
	border-radius: var(--borderRadius);
	border: 1px solid var(--grey-400);
	padding: 5px;
}
input[type="date"] {
	appearance: none;
	background-color: var(--white);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 0s ease-in-out 9999999s;
}

/* alert */
@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.loading {
	width: 6rem;
	height: 6rem;
	border: 5px solid var(--grey-400);
	border-radius: 50%;
	border-top-color: var(--theme);
	animation: spinner 0.6s linear infinite;
}
.loading {
	margin: 0 auto;
}

/* title */
.title {
	text-align: center;
}

.title-underline {
	background: var(--primary-500);
	width: 7rem;
	height: 0.25rem;
	margin: 0 auto;
	margin-top: -1rem;
}

/*
=============== 
Navbar
===============
*/

.navbar {
	height: var(--navbar-height);
	width: 100%;
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	background-color: var(--theme);
	color: var(--black);
	user-select: none;
	z-index: 99;
}

.navbar .title {
	font-size: 1.25rem;
	line-height: 1.5;
	margin-left: 1rem;
	cursor: pointer;
}

.navbar .title a {
	text-decoration: none;
	font-weight: 700;
	color: var(--white);
}

.navbar img {
	width: 30px;
	float: left;
	margin-right: 10px;
}

.navbar .navigation-menu {
	margin-left: auto;
}

.navbar .navigation-menu ul {
	display: flex;
	padding: 0;
}
.navbar .navigation-menu li {
	list-style-type: none;
	margin: 0 1.2rem;
}
.navbar .navigation-menu li a {
	display: block;
	width: 100%;
	color: var(--white);
	text-decoration: none;
	cursor: pointer;
}

@media (max-width: 1400px) {
	.navbar .hamburger {
	  display: block !important;
	}
	
	.navbar .navigation-menu ul {
		display: none;
	}
	.navbar .navigation-menu.expanded ul {
		display: block;
	}
	
	.navbar .navigation-menu ul {
		position: absolute;
		top: var(--navbar-height);
		left: 0;
		flex-direction: column;
		width: 100%;
		height: 100vh;
		background-color: var(--white);
	}
	.navbar .navigation-menu li {
		text-align: center;
		margin: 0;
	}
	.navbar .navigation-menu li a {
		color: #000000 !important;
		width: 100%;
		padding: 1.5rem 0;
	}
	.navbar .navigation-menu li:nth-child(odd) {
		background-color: var(--lightTheme);
	}
	.navbar .navigation-menu li:hover {
		background-color: var(--lightGray);
	}
	.navbar .navigation-menu li:hover a {
		color: var(--theme) !important;
	}
}

.navbar .hamburger {
	border: 0;
	height: 40px;
	width: 40px;
	padding: 0.5rem;
	border-radius: 50%;
	background-color: var(--theme);
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	display: none;
}
.navbar .hamburger:hover {
	background-color: var(--darkTheme);
}

/*
=============== 
Footer
===============
*/

.footer {
	height: var(--footer-height);
    background-color: var(--darkTheme);
    padding: 1rem;
}

.footer p {
    margin: 0 0 0.8rem;
    font-size: 0.9rem;
    color: var(--white);
}

.footer a {
    color: var(--white);
    font-weight: 700;
}
.footer a:hover {
	text-decoration: underline;
}

/*
=============== 
Page
===============
*/

.page {
	min-height: calc(100vh - (var(--navbar-height) + var(--footer-height)));
	padding-bottom: 2rem;
}

section {
	padding: 1rem;
}

/*
=============== 
Baròmetre
===============
*/

table {
	border-collapse: collapse;
	margin-top: 1rem;
}

th {
	text-align: center;
	background-color: var(--theme);
	color: var(--white);
}

.grup0 {
	background-color: var(--s0);
	color: var(--black);
}
.grup1 {
	background-color: var(--s1);
	color: var(--black);
}
.grup2 {
	background-color: var(--s2);
	color: var(--black);
}
.grup3 {
	background-color: var(--s3);
	color: var(--black);
}
.grup4 {
	background-color: var(--s4);
	color: var(--white);
}
.grup5 {
	background-color: var(--s5);
	color: var(--white);
}
.grup6 {
	background-color: var(--s6);
	color: var(--white);
}
.grup7 {
	background-color: var(--s7);
	color: var(--white);
}
.grup8 {
	background-color: var(--s8);
	color: var(--white);
}

.barometre-tb th,
.barometre-tb td {
	padding: 0.25rem;
}

.barometre-tb tr th:nth-child(4) {
	font-family: 'PT Sans Narrow';
	font-weight: 400;
	letter-spacing: 1px;
	font-size: 0.85rem;
}
.barometre-tb tr th:nth-child(5) {
	font-weight: 300;
	white-space: nowrap;
}

.barometre-tb tr td:nth-child(1) {
	text-align: center;
}
.barometre-tb tr td:nth-child(2) {
	background-color: var(--gray);
	text-align: right;
}
.barometre-tb tr td:nth-child(3) {
	padding-right: 1rem;
}
.barometre-tb tr td:nth-child(2n+4) {
	text-align: center;
	min-width: 25px;
}
.barometre-tb tr td:nth-child(12) {
	background-color: var(--theme);
}

.barometre-tb .carregat {
	background-color: var(--cc) !important;
	color: var(--black) !important;
}

.barometre-tb .same {
	background-color: var(--gray);
}
.barometre-tb .same::after {
	vertical-align: bottom;
	content: '=';
}
.barometre-tb .up {
	background-color: var(--green);
}
.barometre-tb .up::after {
	vertical-align: bottom;
	content: '▲';
}
.barometre-tb .down {
	background-color: var(--orange);
}
.barometre-tb .down::after {
	vertical-align: bottom;
	content: '▼';
}

.barometre-tb .new {
	position: relative;
}
.barometre-tb .new::after {
	vertical-align: bottom;
	content: '✦';
	font-size: 1.2em;
	color: var(--theme);
	position: absolute;
	top: 5%;
	left: 17%;
}
.barometre-tb .new.windowsOS::after {
	top: -11%;
	left: 19%;
}

.bars-container {
	height: 1rem;
	display: flex;
	flex-wrap: nowrap;
}

.bars-container .bar {
	width: calc(100% / 4 - 5px);
	margin: 0 auto;
	align-self: flex-end;
}

.bars-container .bar.castell {	background-color: var(--white); }
.bars-container .bar.pilar {	background-color: black; }

.barometre-footer {
	margin-top: 1rem;
	font-size: 0.8rem;
	color: var(--grey-400);
	max-width: 800px;
}

.barometre-footer p {
	margin-bottom: 1rem;
}

.barometre-footer h5 {
	color: var(--grey-500);
	font-size: 1rem;
	margin-bottom: 0.3rem;
}

.other-seasons {
	display: flex;
	flex-wrap: wrap;
	gap: 2px;
}

.other-seasons a {
	font-family: 'Share Tech Mono';
	font-size: 0.7rem;
	white-space: nowrap;
	padding: 2px 5px;
	border-radius: 2px;
	background-color: var(--theme);
	color: var(--white);
}
.other-seasons a.disabled {
	pointer-events: none;
	background-color: var(--grey-300);
	color: var(--grey-400);
	opacity: 1 !important;
}
.other-seasons a.unsel {
	opacity: 0.3;
}
.other-seasons a.unsel:hover {
	opacity: 1;
}

@media (max-width: 500px) {
	.barometre-tb {
		font-size: 0.75rem;
	}

	.barometre-tb th,
	.barometre-tb td {
		padding: 2px;
	}

	.barometre-tb  tr td:nth-child(2n+4) {
		min-width: 20px;
	}

	.barometre-tb tr th:nth-child(4) {
		font-size: 10px;
	}

	.barometre-tb tr th:nth-child(5),
	.barometre-tb tr td:nth-child(12) {
		display: none;
	}

	.barometre-tb .new::after {
		top: 0 !important;
		left: 15% !important;
	}
}

/*
=============== 
Score Table
===============
*/

.subtitle {
	margin: 1rem 0;
}

p a {
	color: var(--theme);
}
p a:hover {
	text-decoration: underline;
}

.score-tb {
	border-spacing: 0;
}

.score-tb th,
.score-tb td {
	padding: 0.15rem 0.25rem;
}

.score-tb th,
.score-tb td {
	border: 1px solid var(--black);
}

.score-tb td.inedit {
	opacity: 0.6;
}

@media (max-width: 500px) {
	.score-tb {
		font-size: 0.75rem;
	}

	.score-tb th,
	.score-tb td {
		padding: 0.1rem 0.2rem;
	}
}

/*
=============== 
Stats
===============
*/

.table-wrap {
	position: relative;
	overflow-x: scroll !important;
	scrollbar-width: none;
}
.table-wrap::-webkit-scrollbar {
	display: none;
}

.stats-tb {
	margin-top: 1rem;
}

.stats-tb th,
.stats-tb td {
	padding: 0.15rem;
	border: 1px solid var(--black);
}

.stats-tb .descarregats,
.stats-tb .carregats,
.stats-tb .intents,
.stats-tb .intents_desmuntats {
	text-align: center;
}

.stats-tb .descarregats {
	background-color: var(--cd);
}
.stats-tb .carregats {
	background-color: var(--cc);
}
.stats-tb .intents {
	background-color: var(--ci);
}

@media (max-width: 500px) {
	.stats-tb {
		font-size: 0.85rem;
	}

	.stats-tb th,
	.stats-tb td {
		padding: 2px;
	}
}

/*
=============== 
Calendar
===============
*/

.calendar {
	width: 800px;
	margin-top: 1rem;
}

#calendar .header {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

#calendar .header span {
	color: var(--grey-500);
}

#calendar .header button {
	width: 2rem;
	height: 2rem;
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--grey-500);
}
#calendar .header button:hover {
	background-color: var(--grey-200);
}

#calendar table {
	width: 100%;
	text-align: center;
	border-collapse: collapse;
}

#calendar th {
	padding-bottom: 0.3rem;
	background-color: transparent;
	color: var(--theme) !important;
}

#calendar td {
	height: 110px;
	overflow: hidden;
	position: relative;
	width: calc(100% / 7);
}
#calendar td:not(.blank) {
	border: 1px solid var(--grey-200);
}
#calendar td:hover .day {
	text-decoration: underline;
}

#calendar .day {
	position: absolute;
	top: 0;
	right: 0;
	user-select: none;
	padding: 1px 3px;
	font-size: 0.8rem;
}
#calendar .weekend .day {
	color: var(--theme);
}
#calendar .today {
	background-color: var(--theme);
}
#calendar .today .day {
	color: var(--white);
}

#calendar .event {
	top: 20px;
	width: calc(100% - 4px);
	padding: 0 2px;
	position: absolute;
	left: 0;
	white-space: nowrap;
}
#calendar .event div {
	font-size: 0.8rem;
	padding-left: 5px;
	border-radius: 3px;
	margin-bottom: 2px;
	line-height: 1.5;
	overflow: hidden;
	text-align: left;
	user-select: none;
}
#calendar .event div:hover {
	opacity: 0.8;
	cursor: pointer;
}

#calendar .jump-today {
	margin-top: 1rem;
	width: 100%;
	text-transform: none;
}

#calendar .event-info {
	padding: 10px;
	padding-right: 50px;
	max-width: 450px;
	position: absolute;
	top: 30%;
	left: 3%;
	background-color: #ffffff;
	border: 3px solid var(--theme);
	box-shadow: var(--shadow-2);
}

#calendar .event-info h2 {
	margin-bottom: 0.75rem;
	font-size: 20px;
}

#calendar .event-info p {
	margin-bottom: 0.5rem;
	line-height: 1.3;
}

#calendar .close {
	cursor: pointer;
}
#calendar .close::after {
	width: 20px;
	height: 20px;
	line-height: 23px;
	top: 5px;
	right: 5px;
	content: '×';
	color: var(--theme);
	background-color: var(--white);
	border: 2px solid var(--theme);
	overflow: hidden;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: var(--transition);
}
#calendar .close:hover::after {
	color: var(--white);
	background-color: var(--theme);
}

@media (max-width: 850px) {
	.calendar {
		width: 98%;
	}
	#calendar td {
		height: 80px;
	}
	#calendar .event {
		width: calc(100% - 2px);
		padding: 0 1px;
	}
	#calendar .event div {
		padding: 0 1px;
		font-size: 9px;
		margin-bottom: 1px;
	}
	#calendar .event-info {
		max-width: 75%;
	}
}

/*
=============== 
Llista
===============
*/

#diades h2 {
	margin-top: 20px !important;
}
#diades h3 {
	margin: 5px 0 !important;
}

.list-tb {
	margin-top: 0;
	margin-bottom: 1.5rem;
}

.list-tb th,
.list-tb td {
	padding: 0.25rem 0.5rem;
	border: 3px solid #ffffff;
}

.list-tb th {
	white-space: nowrap;
}

.list-tb td {
	background-color: var(--grey-100);
}

.list-tb th:nth-child(1),
.list-tb td:nth-child(1) {
	padding-right: 0.5rem;
	padding-left: 1rem;
	border-left: none;
	text-align: right;
}


.list-tb .invalid {
	color: var(--grey-400);
}

@media (max-width: 500px) {
	.list-tb {
		font-size: 0.75rem;
	}

	.list-tb th,
	.list-tb td {
		padding: 2px 5px;
	}
}

/*
=============== 
Calculator
===============
*/

.button-wrap {
	display: flex;
	width: 800px;
	justify-content: space-between;
	margin-bottom: 2.5rem;
}

.button-wrap * {
	width: calc(50% - 0.5rem);
	text-align: center;
}

.calculator-tb.computer {
	width: 800px;
}

.calculator-tb th,
.calculator-tb td {
	border: 1px solid black;
	text-align: left;
	padding: 0.25rem 0.5rem;
}

.colla-name {
	width: 100%;
}

.calculator-tb td:has(.carregat-checkbox:checked) {
	background-color: var(--cc);
}

.calculator-tb tr td:nth-child(1),
.calculator-tb tr td:nth-child(2),
.calculator-tb tr td:nth-child(3),
.calculator-tb tr td:nth-child(4),
.calculator-tb tr td:nth-child(5) {
	padding: 0;
}
.calculator-tb tr td:nth-child(6) {
	text-align: center;
}

.input-wrap {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.calculator-tb input {
	border: none;
	outline: none;
	border-radius: 0;
}

.carregat-checkbox {
	margin: 0 1px 0 5px;
}

.castells-selector {
	border: none;
	outline: none;
	border-radius: 0;
	background-color: transparent;
	padding: 5px 3px;
	width: 100%;
	color: var(--black);
}

.select-arrow {
	width: 100%;
}

.dblarrow {
	margin-left: -15px;
	display: inline-block;
	pointer-events: none;
}

.dblarrow b {
	width: 0; 
	height: 0; 
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid black;
	display: block;
	margin-bottom: 3px;
}

.dblarrow i {
	width: 0; 
	height: 0; 
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid black;
	display: block;
	margin-bottom: -1px;
}

.calc-btn {
	padding: 0 !important;
	padding-top: 1rem !important;
	border: none !important;
}
.calc-btn button {
	width: 100%;
	text-transform: none;
}

.mobile {
	display: none;
}

.calculator-tb.mobile tr th:nth-child(1) {
	padding: 0;
}

@media (max-width: 850px) {
	.button-wrap {
		flex-direction: column;
		width: 100%;
	}

	.button-wrap * {
		width: 100%;
	}
	.button-wrap :nth-child(1) {
		margin-bottom: 3px;
	}

	.computer {
		display: none;
	}

	.mobile {
		display: table;
		width: 100%;
	}

	.calculator-tb input {
		background-color: var(--theme);
		color: var(--white);
		font-weight: bold;
	}

	.calculator-tb tr th:nth-child(2) {
		text-align: center;
	}
}

/*
=============== 
Collaborate
===============
*/

#collaborate .image {
	margin: 50px auto;
	text-align: center;
}

#collaborate img {
	width: 95%;
	max-width: 300px;
	border-radius: 50%;
}

/*
=============== 
Error 404
===============
*/

.error-page {
	padding-top: 5rem;
	text-align: center;
}

.error-page h1 {
	font-size: 9rem;
	margin: 0;
	font-family: 'Share Tech Mono';
}

.error-page h3 {
	margin-bottom: 0;
	font-family: 'Share Tech Mono';
}

/*
=============== 
Reorganize Scores
===============
*/

.reorganize tbody tr {
	background-color: var(--backgroundColor);

	&:has(input.castell-grup[value="0"]) {
		background-color: var(--s0);
		color: var(--black);

		& input {
			background-color: var(--s0);
			color: var(--black);
		}
	}

	&:has(input.castell-grup[value="1"]) {
		background-color: var(--s1);
		color: var(--black);

		& input {
			background-color: var(--s1);
			color: var(--black);
		}
	}

	&:has(input.castell-grup[value="2"]) {
		background-color: var(--s2);
		color: var(--black);

		& input {
			background-color: var(--s2);
			color: var(--black);
		}
	}

	&:has(input.castell-grup[value="3"]) {
		background-color: var(--s3);
		color: var(--black);

		& input {
			background-color: var(--s3);
			color: var(--black);
		}
	}

	&:has(input.castell-grup[value="4"]) {
		background-color: var(--s4);
		color: var(--white);

		& input {
			background-color: var(--s4);
			color: var(--white);
		}
	}

	&:has(input.castell-grup[value="5"]) {
		background-color: var(--s5);
		color: var(--white);

		& input {
			background-color: var(--s5);
			color: var(--white);
		}
	}

	&:has(input.castell-grup[value="6"]) {
		background-color: var(--s6);
		color: var(--white);

		& input {
			background-color: var(--s6);
			color: var(--white);
		}
	}

	&:has(input.castell-grup[value="7"]) {
		background-color: var(--s7);
		color: var(--white);

		& input {
			background-color: var(--s7);
			color: var(--white);
		}
	}

	&:has(input.castell-grup[value="8"]) {
		background-color: var(--s8);
		color: var(--white);

		& input {
			background-color: var(--s8);
			color: var(--white);
		}
	}
}

.reorganize span {
	user-select: none;
	cursor: grab;
}

.reorganize input {
	border: none;
	padding: 0;
	width: 75px;
}

/*
===============

===============
*/
